import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "ZjU2MWYwM2U5NGI2YzA2ZWEzZGNhOGY2OTQ2NzI0ZDU=",
	"Tenant-Access-Key": "tftBrskGZj3qPA",
};

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
